body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.App-map {
  height: 50vh;
  width: 100%;
}

/*.react-pdf__Page__canvas {*/
/*  margin: 8px auto;*/
/*  width: 90% !important;*/
/*  height: 100% !important;*/
/*}*/
